/* HomePage.css */

.home-container {
    height: 100vh; /* Hauteur complète de la fenêtre de visualisation */
    display: flex;
    align-items: center; /* Centre le contenu verticalement */
    justify-content: center; /* Centre le contenu horizontalement */
    position: relative; /* Position relative pour positionner correctement le fond */
}

.background-blur {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('./chatbg.png'); /* Chemin vers votre image */
    background-size: cover; /* Assure que l'image couvre entièrement la div */
    background-position: center; /* Centre l'image dans la div */
    filter: blur(8px); /* Ajoute un effet flou à l'image de fond */
    z-index: -1; /* Assure que le fond est derrière le contenu */
}

.content {
    position: relative;
    background: rgba(255, 255, 255, 0.8); /* Légère couche blanche pour améliorer la lisibilité */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1; /* S'assure que le contenu est au-dessus du fond */
}
