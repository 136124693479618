/* Header.css */

.header {
    background-color: #2a3d66; /* Bleu personnalisé */
    color: white; /* Texte blanc pour un contraste élevé */
}

.navbar {
    background-color: #2a3d66; /* Assurez-vous que le bleu s'applique également à la navbar si elle ne prend pas la couleur du parent */
}

.navbar-brand, .navbar-nav .nav-link {
    color: white; /* Assurez-vous que les liens sont visibles */
}

/* Surcharge des couleurs par défaut de Bootstrap pour les liens du navbar en état hover */
.navbar-nav .nav-link:hover {
    background-color: #1a2a46; /* Une teinte plus foncée de bleu pour l'effet hover */
    color: #fff;
}
